import { ICreateTaskRequest, IGetTasksRequest, ILoginRequest } from "interfaces";
import { axiosExchange } from "./axios";

export enum ENDPOINTS {
  user = "auth/login",
  createTask = "tasks",
  tasks = "tasks",
}

const user = async ({ ...request }: ILoginRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.user}`,
    request
  );
}

const createTask = async ({ ...request }: ICreateTaskRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.createTask}/`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const tasks = async ({ dateStart, dateEnd }: IGetTasksRequest) => {
  const filteredParams = Object.entries({ dateStart, dateEnd })
    .filter(([_, value]) => value != null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const queryString = new URLSearchParams(filteredParams).toString();

  return axiosExchange.get(
    `/${ENDPOINTS.tasks}/?${queryString}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    }
  );
}

const service = {
  user,
  createTask,
  tasks,
};

export default service;