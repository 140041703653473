import { createSlice } from '@reduxjs/toolkit';
import { UIState } from '../../types/storeTypes';


const modulePrefix = 'ui';

const initialState: UIState = {
  isOpenCreateTaskModal: false,
  snacks: [],
};

export const uiSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {
    toggleCreateTaskModal(state) {
      state.isOpenCreateTaskModal = !state.isOpenCreateTaskModal;
    },
    addSnack(state, action) {
      state.snacks = [...state.snacks, action.payload]
    },
    removeSnack(state, action) {
      state.snacks = state.snacks.filter(({ id }) => id !== action.payload)
    },
  }
});

const { actions, reducer } = uiSlice;

export const {
  toggleCreateTaskModal,
  addSnack,
  removeSnack,
} = actions;

export default reducer;
