import { openCreateTaskSelector, toggleCreateTaskModal } from "../../../features/index";
import { useAppDispatch, useAppSelector } from "../../../store/index";
import { Button, Modal } from "../../elements";
import { EventCalendar } from "./EventCalendar";
import styles from "./main.module.sass"
import TaskCreationForm from "./TaskCreationForm/TaskCreationForm";

const Main = () => {
  const dispatch = useAppDispatch();
  const isOpenCreateTaskModal = useAppSelector(openCreateTaskSelector)

  const toggleNewTaskModal = () => {
    dispatch(toggleCreateTaskModal())
  }

  return (
    <main className={styles.main}>
      <Button content={"Создать задачу"} onClick={toggleNewTaskModal} />
      <Modal isShow={isOpenCreateTaskModal} onHide={toggleNewTaskModal} title={"Создание задачи"}>
        <TaskCreationForm />
      </Modal>
      <EventCalendar />
    </main>
  )
}

export default Main;